// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    EmailTemplates as _EmailTemplates,
} from "@containers";

/**
 * e-mailsjablonen
 */
const EmailTemplates = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="E-mailsjablonen">
            <_EmailTemplates />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(EmailTemplates);
